import React from 'react'
const CountryRegionData = require('country-region-data/data.json')


function getFullCountry(countryCode) {
  return CountryRegionData.find(
    function (item) {
      return item.countryShortCode === countryCode;
    }
  )
}


const Country = ({ countryCode }) => (
  <span className="country">
    {getFullCountry(countryCode).countryName }
  </span>
)



export default Country
