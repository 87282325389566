import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import './styles/blog.css'
import './styles/story.css'
import CloudinaryImage from '../components/CloudinaryImage'
import Seo from '../components/Seo'
import Carousel, { Modal, ModalGateway } from 'react-images'
import kebabCase from 'lodash.kebabcase'
import Country from '../components/Country'

const generateImageURl = (image, dimentions = 'c_fill,h_400,w_400') =>
  `https://res.cloudinary.com/dvjhq5dpg/image/fetch/${dimentions}/v1546964241/https://dev-skillsailors.pantheonsite.io${image}`

const Story = ({ data }) => {
  const [lightBoxOpen, setLightBoxOpen] = useState(false)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const imageSources = data.nodeStory.relationships.field_image
    ? data.nodeStory.relationships.field_image.map(_ => {
        return {
          src: generateImageURl(_.uri.url, 'w_1200,c_scale'),
        }
      })
    : []

  return (
    <Layout>
      <Seo title="Stories" />
      <div className="layout-narrow">
        <Link
          className="blog__link"
          to={`/trip/${
            data.nodeStory.relationships.field_trip_reference
              .drupal_internal__nid
          }/${kebabCase(
            data.nodeStory.relationships.field_trip_reference.title
          )}`}
        >
          {
            data.nodeStory.relationships.field_trip_reference.field_address_1
              .locality
          }
          ,{' '}
          <Country
            countryCode={
              data.nodeStory.relationships.field_trip_reference.field_address_1
                .country_code
            }
          />
        </Link>

        <div className="story__wrapper">
          <div className="story__top">
            <div className="story__data-wrapper">
              <h1 className="story__title">{data.nodeStory.title}</h1>
              <h2 className="story__subtitle">
                {data.nodeStory.field_subtitle}
              </h2>

              <div className="story__data">
                <img
                  alt="blog icon"
                  className="story__icon"
                  src="/profile.svg"
                />
                <div className="story__data-author">
                  <div className="story__author-name">
                    {data.nodeStory.relationships.uid.name}
                  </div>
                  <div className="blog__date">{data.nodeStory.created}</div>
                </div>
              </div>
            </div>
            <div className="story__image">
              {data.nodeStory.relationships.field_image && (
                <CloudinaryImage
                  class="blog__image"
                  height="505"
                  width="760"
                  src={data.nodeStory.relationships.field_image[0].uri.url}
                />
              )}
            </div>
          </div>

          {data.nodeStory.relationships.field_image &&
            data.nodeStory.relationships.field_image.length > 1 && (
              <div>
                <ul className="flex py-2">
                  {data.nodeStory.relationships.field_image
                    .reverse()
                    .map((img, index) => {
                      return (
                        <li className="mr-2">
                          <img
                            alt="Trip"
                            onClick={() => {
                              setSelectedIndex(index)
                              setLightBoxOpen(!lightBoxOpen)
                            }}
                            src={generateImageURl(
                              img.uri.url,
                              'w_100,h_100,c_fill'
                            )}
                          />
                        </li>
                      )
                    })}
                </ul>

                <ModalGateway>
                  {lightBoxOpen && (
                    <Modal
                      onClose={() => {
                        setLightBoxOpen(false)
                      }}
                    >
                      <Carousel
                        frameProps={{ autoSize: 'height' }}
                        currentIndex={selectedIndex}
                        views={imageSources}
                      />
                    </Modal>
                  )}
                </ModalGateway>
              </div>
            )}
          <div
            className="blog__body"
            dangerouslySetInnerHTML={{ __html: data.nodeStory.body.value }}
          ></div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($nid: Int) {
    nodeStory(drupal_internal__nid: { eq: $nid }) {
      id
      title
      field_subtitle
      created(formatString: "MMM DD YYYY")
      body {
        value
      }
      relationships {
        uid {
          name
        }
        field_image {
          uri {
            url
          }
        }
        field_trip_reference {
          field_address_1 {
            locality
            country_code
          }
          title
          drupal_internal__nid
        }
      }
    }
  }
`

export default Story
