import React from 'react'

const CloudinaryImage = props => {
  const height = props.height || '410'

  const width = props.width || '1200'

  const cloudinaryUrl =
    'https://res.cloudinary.com/dvjhq5dpg/image/fetch/h_' +
    height +
    ',w_' +
    width +
    ',c_fill/v1546964241/https://dev-skillsailors.pantheonsite.io'

  return (
    <img
      alt={props.src}
      className={props.className}
      src={cloudinaryUrl + props.src}
    />
  )
}

export default CloudinaryImage
